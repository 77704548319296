import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components/macro'

import ControlledInput from '../lib/ControlledInput'
import Button from '../lib/Button'
import { boxShadows } from '../lib/globals'
import loginList from '../lib/loginList'

const SigningSheet = styled.div({
  position: 'relative',
  padding: '20px 50px 70px',
  maxWidth: 380,
  margin: '0 auto',
  backgroundColor: '#fff',
  boxShadow: boxShadows.sheet,
  borderRadius: 6,
})
const Header = styled.div({
  fontSize: 38,
  margin: '20px 0 30px',
  textAlign: 'center',
})
const ValidationError = styled.div({
  backgroundColor: '#ffd642',
  marginBottom: 10,
  padding: '10px 5px',
  borderRadius: 4,
})

const defaultState = {
  username: '',
  password: '',
}

function authenticate(username, password) {
  if (loginList.hasOwnProperty(username)) {
    return loginList[username] === password
  } else {
    return false
  }
}

class Login extends Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }
  state = {
    ...defaultState,
    isWrongCredentials: false,
  }
  handleChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
  validateForm = () => {
    const { username, password } = this.state
    const isInvalid = !username || !password
    return isInvalid
  }
  handleSubmit = event => {
    event.preventDefault()
    const { username, password } = this.state
    const isAuthencticated = authenticate(username, password)
    if (isAuthencticated) {
      window.localStorage.setItem(
        'isAuthenticated',
        JSON.stringify({ value: true, username }),
      )
      window.location = "/"
    } else {
      this.setState({
        ...defaultState,
        isWrongCredentials: true,
      })
      this.myRef.current.focus()
    }
  }
  render() {
    const { username, password, isWrongCredentials } = this.state
    return (
      <>
        <Header>Förskolan</Header>
        <SigningSheet>
          <ValidationError css={{ opacity: isWrongCredentials ? 1 : 0 }}>
            Ogiltigt användarnman eller lösenord
          </ValidationError>
          <form className="form" onSubmit={this.handleSubmit}>
            <ControlledInput
              autoFocus
              ref={this.myRef}
              inputType="text"
              name="username"
              placeholder="Användarnamn"
              value={username}
              onChange={this.handleChange}
            />
            <ControlledInput
              inputType="password"
              name="password"
              placeholder="Lösenord"
              value={password}
              onChange={this.handleChange}
            />
            <Button isFullWidth type="submit" disabled={this.validateForm()}>
              Logga in
            </Button>
          </form>
        </SigningSheet>
      </>
    )
  }
}

export default withRouter(Login)
