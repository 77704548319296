import React, {Component} from 'react'
import styled from 'styled-components/macro'
import Button from '../../lib/Button'
const HeaderWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  textAlign: 'center',
  padding: '0px 7px',
  paddingBottom: '2em'
})

const Title = styled.h1({
  marginTop: '0',
  fontSize: '2em',
})
const TitleInput = styled.input({
  display: 'flex',
  textAlign: 'center',
  fontSize: '2em',
  width: '500px',
  borderRadius: '8px',
  border: '1px solid black',
  padding: '5px',
})
const DescriptionInput = styled.textarea({
  display: 'flex',
  marginTop: '1em',
  marginBottom: '1em',
  width: '500px',
  height: '120px',
  paddingTop: '.5em',
  paddingLeft: '.5em',
  paddingRight: '.5em',
  resize: 'none',
  borderRadius: '8px',
  border: '1px solid black',

})
const HeaderDescription = styled.h2({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  textAlign: 'center',
  fontSize: '17px',
  fontWeight: '600',
  padding: '0px 4em 0px 4em',
  paddingBottom: '1em'
})

export default class SituationHeader extends Component {
  
  constructor(props) {
    super()
    var saved = false;
    if(props.situation.title) {
      saved = true
    }
    this.state = {
      saved: saved
    }
  }
  
  setHeader(e) {
    this.props.setHeaders(e.target[0].value, e.target[1].value)
    this.setState({
      saved: true
    })
  }
  
  render() {
    if(this.props.situation.cards.custom) {
      return (
        <HeaderWrapper>
          <form onSubmit={(e) => {e.preventDefault(); this.setHeader(e)}}>
            <TitleInput 
              placeholder="Fyll i situationens titel" 
              defaultValue={this.props.situation.title && this.props.situation.title}
              onChange={e => {this.setState({saved: false})}}
              />
            <DescriptionInput 
              placeholder="Beskriv situationen och vad det var för aktivitet barnet skulle delta i när hindret dök upp.
              
              Finns det mönster som särskild plats, tidpunkt eller att specifika personer var med kan du även skriva ner dessa." 
              defaultValue={this.props.situation.description && this.props.situation.description}
              onChange={e => {this.setState({saved: false})}}
              />
            <Button blue={this.state.saved} small={1} type="submit" name="submit">
              {
                this.state.saved ? ' Sparad  ' : '  Spara'
              }
            </Button>
          </form>
        </HeaderWrapper>
      )
    }
    return (
      <HeaderWrapper>
        <Title>{this.props.situation.title}</Title>
        <HeaderDescription>{this.props.situation.description}</HeaderDescription>
      </HeaderWrapper>
    )
  }
}
