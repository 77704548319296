import React, {Component} from 'react'
import styled from 'styled-components/macro'
import Button from '../../lib/Button'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  marginBottom: '5%'
})

const Label = styled.label({
  marginBottom: '.5em'
})

const Form = styled.form({
  display: 'flex',
  flexDirection: 'column',
  width: '80%',
})

const TextArea = styled.textarea({
  width: '100%',
  height: '70px',
  padding: '10px 0 20px 10px',
  border: 'solid #8080805c 0.5px',
  borderRadius: '8px',
  color: 'black',
  display: 'flex',
  fontSize: '16px',
  resize: 'none',
  marginBottom: '10px'
})

export default class CustomCard extends Component {
  onChangefunc() {
    if (this.props.card.active) {
      this.props.changeActive(false)
    }
  }

  render() {
    return (
      <Wrapper>
        <Form onSubmit={(e) => {e.preventDefault(); this.props.handleSubmit(e)}}>
          <Label>Beskriv beteendehinder </Label>
          <TextArea css={{backgroundColor: '#e5e5e5', fontWeight: '600', border: 'solid black 0.5px'}}
            defaultValue={this.props.card.beteendehinder && this.props.card.beteendehinder}
            name="description"
            type="text"
            onFocus={()=>this.props.setSelected(1)}
            onChange = {e => this.onChangefunc()}/>

          <Label>Beskriv önskat beteende</Label>
          <TextArea
            defaultValue={this.props.card.onskelage && this.props.card.onskelage}
            name="wish"
            type="text"
            onFocus={()=>this.props.setSelected(2)}
            onChange = {e => this.onChangefunc()}/>

          <Label>Beskriv insats</Label>
          <TextArea css={{marginBottom: '2em'}}
            defaultValue={this.props.card.atgarder[0] && this.props.card.atgarder[0].text}
            name="effort"
            type="text"
            onChange = {e => this.onChangefunc()}
            />
          <div css={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
            <Button blue={this.props.card.active} small={1} type="submit" name="submit">
              {
                (this.props.card.active) ?
                  '  Sparad  '
                  :
                  '  Spara  '
              }
            </Button>
          </div>
        </Form>
        <hr css={{width: '90%', margin: '5% 0'}}/>
      </Wrapper>
    )
  }
}
