import React from 'react'
import styled from 'styled-components/macro'
import TextOnlyButton from './TextOnlyButton'
import image from './images/logga.png'

const Wrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '10px 35px 10px 10px',
  alignItems: 'center',
})

function Logo() {
  return (
    <div css={{ display: 'flex', alignItems: 'center' }}>
      <img css={{ height: 60 }} src={image} alt="" />
      <div css={{ opacity: 0.7 }}>När ett hinder uppstår</div>
    </div>
  )
}

function TopBar({ isAuthenticated }) {
  return (
    <Wrapper>
      <Logo />
      {isAuthenticated && (
        <TextOnlyButton
          onClick={() => {
            window.localStorage.clear()
            // if (isProductionEnv()) {
            //   window.Intercom('shutdown')
            // }
            window.location = "/"
          }}
        >
          Logga ut
        </TextOnlyButton>
      )}
    </Wrapper>
  )
}

export default TopBar
