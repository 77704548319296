import React from 'react'
import styled from 'styled-components/macro'
import { Spring } from 'react-spring'
import { colors } from '../../lib/globals'

const Wrapper = styled.div({
  width: '240px',
  backgroundColor: colors.baseGreen,
  color: '#fff',
  marginLeft: 20,
  borderRadius: 4,
  padding: '10px 5px 10px 10px',
  marginBottom: 10,
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  display: 'inline-block',
  height: 'auto',
})

const Title = styled.div({
  fontSize: 26,
  marginBottom: 10,
  lineHeight: '30px',
})
const Text = styled.div({
  marginBottom: 10,
  fontSize: '16px',
  fontWeight: 300,
})

function Expanded(input) {
  return (
    <Spring
      from={{ opacity: 0.5 }}
      config={{ duration: 300 }}
      to={{ opacity: 1 }}
    >
      {props => (
        <Wrapper onClick={() => input.setSelected(input.index)}>
           <Title>{input.item.title}</Title>
           {
             input.selected && 
             <Text>{input.item.information}</Text>
           }
           
        </Wrapper>
      )}
    </Spring>
  )
}

function Explanation(props) {
  return (
    <div>
      {props.fieldInformation.map((item, index) => {
        if(props.custom && index===0) {
          return null
        }
          let selected = props.selectedInfoCard===index
          return <Expanded index={index} setSelected={props.setSelected} selected={selected} key={index} item={item}/>
      })}
    </div>
  )
}

export default Explanation
