import React from 'react'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router-dom'

import { colors } from './globals'

const Wrapper = styled.div({
  margin: '50px 35px',
  display: 'flex',
  justifyContent: 'space-between',
})
const Step = styled.div(({ isCurrent }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontWeight: 600,
  paddingBottom: 5,
  borderBottom: '3px solid',
  borderBottomColor: isCurrent ? colors.baseGreen : 'transparent',
  opacity: isCurrent ? 1 : 0.3,
}))
const Label = styled.div({
  fontSize: 10,
})
const Content = styled.div({
  fontSize: 15,
})

const data = [
  {
    path: 'start',
    label: 'STEG 1',
    content: 'Introduktion',
  },
  {
    path: 'situations',
    label: 'STEG 2',
    content: 'Situationer',
  },
  {
    path: 'behavior',
    label: 'STEG 3',
    content: 'Hinder & Önskeläge',
  },
  {
    path: 'summary',
    label: 'STEG 4',
    content: 'Sammanfattning',
  },
]

function Steps({ location: { pathname } }) {
  return (
    <Wrapper>
      {data.map(({ path, label, content }, index) => {
        const isCurrent = pathname.includes(path)
        return (
          <Step isCurrent={isCurrent} key={index}>
            <Label>{label}</Label>
            <Content>{content}</Content>
          </Step>
        )
      })}
    </Wrapper>
  )
}

export default withRouter(Steps)
