import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'
import { withRouter } from "react-router";
import Sheet from '../lib/Sheet'
import Button from '../lib/Button'
import fragetecken from '../lib/images/fragetecken.jpg'
import plustecken from '../lib/images/plus.png'
const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-around'
})

const Card = styled.div({
  width: '30%',
  height: 250,
  border: '1px solid #ccc',
  borderRadius: '8px',
  marginBottom: '10%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  boxShadow: '2px 2px 17px 2px #ccc',
  backgroundColor: '#e5e5e5',
  '&:hover':{
    backgroundColor: '#2da562',
    opacity: '0.8',
  }
})

const SituationsTitle = styled.p({
  color: 'black',
  fontSize: '100%',
  fontWeight: 'bold',
  margin: 0,
  padding: '2%',
  height: '20%'
})

const AmountSelected = styled.div({
  height: '30px',
  width: '30px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '10px',
})

const Img = styled.img({
  width: '100%',
  height: '100%',
  margin: 0,
  padding: 0,
  overflow: 'auto',
  borderRadius: '8px 8px 0 0',

})

const HeaderWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  textAlign: 'center',
  marginBottom: '2em',
})


class Situations extends Component {
  constructor(props){
    super()
    this.state = {...props.location.state}
  }
  componentDidMount() {
    window.scrollTo(0, 0)
  }
  
  addCard() {
    this.setState(state => {
      let situation = {
        selected: 0,
        customCards: [{changed: false, active: false, beteendehinder: undefined, onskelage: undefined, atgarder: []}],
        cards: {
          cards: [],
          custom: true
        }
      }
      state.data.push(situation)
      return state
    })
  }
  render() {
    if(!this.props.location.state) {
      return <Redirect to={'/start'} />
    }
    const situations = this.state.data.map((situation, index) => (
        <Card key={index}
          as={Link}
          to={{
            pathname: '/behavior',
            state: {...this.state, currentSituation: index, isCustom: false}
          }}>
            <Img src={situation.cards.custom ? fragetecken : situation.image.fields.file.url}></Img>
              <SituationsTitle>{situation.title ? situation.title : "Ny situation"}</SituationsTitle>
              <AmountSelected>
                <p css={{ fontWeight: 700 }}>{this.state.data[index].selected}</p>
              </AmountSelected>
        </Card>
    ))

    return (
      <Sheet css={{ marginBottom: 100 }}>
      <HeaderWrapper>
        <h1>{this.props.situationsHeader[0].title}</h1>
        <p>{this.props.situationsHeader[0].information} </p>
      </HeaderWrapper>
        <Wrapper>
          {situations}
          <Card onClick={this.addCard.bind(this)}>
              <Img src={plustecken}></Img>
              <SituationsTitle>Skapa egen situation</SituationsTitle>
                <AmountSelected>
                  <p css={{ fontWeight: 700 }}></p>
                </AmountSelected>
          </Card>
        </Wrapper>
        <div css={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button
            as={Link}
            to={{
              pathname: '/summary',
              state: {...this.state}
            }}
          >
            Skapa rapport →
          </Button>
        </div>
      </Sheet>
    )
  }
}

export default withRouter(Situations)
