import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import Button from '../lib/Button'
import Sheet from '../lib/Sheet'

const Section = styled.div({
  borderBottom: '1px solid #eee',
  marginBottom: 40,
  paddingBottom: 20,
})
const Paragraph = styled.div({
  marginBottom: 15,
})

const BottomSection = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

function Start({ introParagraphs }) {
  return (
    <Sheet css={{ marginBottom: 100 }}>
      {introParagraphs.map(({emoji, title, information}, index) => (
        <Section key={index}>
          <Paragraph css={{ fontWeight: 600, fontSize: 18 }}>
            <span css={{ marginRight: 5 }}>{emoji}</span> {title}
          </Paragraph>
          <Paragraph
            css={{
              whiteSpace: 'pre-line',
            }}
          >
            {information}
          </Paragraph>
        </Section>
      ))}
      <BottomSection>
        <Button as={Link} to="/personalinfo">
          Sätt igång →
        </Button>
      </BottomSection>
    </Sheet>
  )
}

export default Start
