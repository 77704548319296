import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'
import ReactToPrint from 'react-to-print'

import Button from '../../lib/Button'
import { ReactComponent as Printer } from '../../lib/images/printer.svg'

const Wrapper = styled.div({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  '@media print': { display: 'none !important' },
})
export function PrintButtons({componentRef1, componentRef2}) {
  return(
    <Wrapper>
      <ReactToPrint
        trigger={() => (
          <Button
            small={1}
            white={1}
            css={{ marginRight: 15, display: 'flex' }}
          >
            <Printer
              css={{
                width: '18px',
                marginRight: '5px',
              }}
            />
          Skriv ut egen beskrivning
          </Button>
        )}
        content={componentRef1}
      />
      <ReactToPrint
        trigger={() => (
          <Button
            small={1}
            white={1}
            css={{ marginRight: 15, display: 'flex' }}
          >
            <Printer
              css={{
                width: '18px',
                marginRight: '5px',
              }}
            />
          Skriv ut checklista
          </Button>
        )}
        content={componentRef2}
      />
    </Wrapper>
  )

}

export function Buttons({data}) {
  return (
    <Wrapper>
      <Button css={{padding: '4px 10px', borderRadius: '4px', backgroundColor: '#fff', border: '1px solid #ccc', color: 'inherit', marginRight: '27em', textDecoration: 'none !important', '&:hover': {backgroundColor: '#eee'
        }}} small={1} white={1} as={Link} to={{
        pathname: '/situations',
        state: data
      }}>
        ← Ändra kartläggning
      </Button>
    <Button css={{marginRight: '15px'}} small={1} white={1} onClick={()=>{
        window.location = "/";
      }}>
        + Skapa ny
      </Button>
    </Wrapper>
  )
}
