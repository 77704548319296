import React, {Component} from 'react'
import styled from 'styled-components/macro'
import iconClick from '../../lib/images/iconClick.png'

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  marginBottom: '5%'
})

const InnerWrapper = styled.div({
  display: 'flex',
  textAlign: 'center',
  flexDirection: 'row',
  width: '80%',
  height: 'auto',
  border: "1px solid black",
  backgroundColor: '#e5e5e5',
  borderRadius: '6px',
  '&:hover': {
    opacity: '0.6'
  }
})

const Text = styled.p({
  display: 'flex',
  padding: '10px 4px 0 8px',
  width: '90%',
  fontWeight: 600,
  cursor: 'pointer'
})
const Header = styled.h2({
  fontSize: '17px',
  fontWeight: '600'
})

const List = styled.ul({
  margin: 0,
  padding: 0
})

const ListElement = styled.li({
  borderRadius: '5px',
  border: 'solid #8080805c 0.5px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  textAlign: 'start',
  margin: '1px',
})

const CheckBox = styled.input({
  width: '55px',
  height: '30px',
  margin: '5px 0 5px 5px',
  padding: 20
})

const HiddenContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  width: '80%'
})

const Atgard = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  margin: '8px 2px 8px 0',
  fontSize: '16px',
  fontWeight: '400'
})

const Img = styled.img({
  margin: 'auto',
  height: '40px'
})

export default class Card extends Component {
  
  render() {
    return (
      <Wrapper>
        <InnerWrapper onClick={() => this.props.toggleContainer(this.props.index)}>
          <Text>{this.props.card.beteendehinder}</Text>
          <Img src={iconClick}></Img>
        </InnerWrapper>
        {
          this.props.show &&
            <HiddenContainer>
              <Header>{this.props.card.onskelage}</Header>
              <List>
                {
                  this.props.card.atgarder.map((atgard, index) => (
                    <ListElement key={index} onClick={(() => this.props.toggleMeasures(this.props.index, index))}>
                    <CheckBox defaultChecked={atgard.selected} type="checkbox"/>
                    <Atgard>{atgard.text}</Atgard>
                  </ListElement>))
                }
              </List>
            </HiddenContainer>
        }
      </Wrapper>
    )
  }
}
