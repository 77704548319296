export default {
  testande: 'avorp880821!',
  samtestar: 'samtestar',
  vindangen: 'falkoping123',
  hastbacken: 'falkoping123',
  urds: 'falkoping123',
  lillagunghasten: 'falkoping123',
  junibacken: 'falkoping123',
  paletten: 'falkoping123',
  sorgarden: 'falkoping123',
  trollet: 'falkoping123',
  mosseberg: 'falkoping123',
  fenix: 'falkoping123',
  gudhem: 'falkoping123',
  vartofta: 'falkoping123',
  asle: 'falkoping123',
  stenstorp: 'falkoping123',
  broddetorp: 'falkoping123',
  kinnarp: 'falkoping123',
  asarp: 'falkoping123',
  yllestad: 'falkoping123',
  odensberg: 'falkoping123',
  floby: 'falkoping123',
  central: 'falkoping123',
  kompassen: 'karlsborg123',
  kvarnbacken: 'karlsborg123',
  ledning: 'karlsborg123',
  myran: 'karlsborg123',
  vatterskolan: 'karlsborg123',
}
