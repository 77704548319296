import React, {Component} from 'react'
import styled from 'styled-components/macro'
import {Link} from 'react-router-dom'
import {withRouter} from "react-router";
import {Redirect} from "react-router-dom"
import Button from '../lib/Button'
import Explanation from './BehaviorComponents/Explanation'
import CustomCard from './BehaviorComponents/CustomCard'
import Card from './BehaviorComponents/Card'
import SituationHeader from './BehaviorComponents/SituationHeader'
import {boxShadows} from '../lib/globals'

const Sticky = styled.div({
  maxWidth: 240,
})

const Sheet = styled.div({
  backgroundColor: '#fff',
  padding: '30px 0px',
  boxShadow: boxShadows.sheet,
  borderRadius: '4px',
  marginBottom: '100px',
  width: '780px'
})

const Outer = styled.div({
    display: 'flex',
    flexDirection: 'row',
    left: '50%',
    marginLeft: '-390px',
    position: 'absolute'
})
const Inner = styled.div({
   display: 'flex',
   flexDirection: 'row',
   width: '780px'
})

const HeaderWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  textAlign: 'center',
  padding: '0px 7px',
  paddingBottom: '2em'
})

const Wrapper = styled.div({
  display: 'flex', flexDirection:
  'row', flexWrap: 'wrap',
  justifyContent: 'space-around',
  marginBottom: '5%'
})

const HeaderDescription = styled.h2({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  alignContent: 'center',
  textAlign: 'center',
  fontSize: '17px',
  fontWeight: '600',
  padding: '0px 4em 0px 4em',
  paddingBottom: '1em'
})

class Behavior extends Component {
  constructor(props) {
    super()
    if(props.location.state) {
      let data = props.location.state.data;



      this.state = {
        selectedInfoCard: 0,
        currentSituation: props.location.state.currentSituation,
        data: data,
        activeCards: props.location.state.isCustom
          ? []
          : Array(props.location.state.data[props.location.state.currentSituation].cards.cards.length).fill(false)
      }
    }

  }

  setHeaders(title, description) {
    this.setState(state => {
      state.data[state.currentSituation].title = title;
      state.data[state.currentSituation].description = description;
      return
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0)

    let sticky = document.getElementById('sticky-div')
    window.onscroll = function() {
        var y = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        if (y < 220) {
            sticky.classList.remove('sticky')
            this.setState({
              selectedInfoCard: 0
            })
        } else {
          sticky.classList.add('sticky')
        }
    }.bind(this)
  }

  componentWillUnmount() {
    window.onscroll = function() {
    }
  }

  setSelected(index) {
    this.setState({
      selectedInfoCard: index
    })
  }

  changeActive(trueOrFalse) {
    this.setState(state=> {
      state.data[state.currentSituation].customCards[0].active = trueOrFalse
      return state
    })
  }

  handleSubmit(e) {
    var card = {
      changed: true,
      active: true,
      beteendehinder: e.target[0].value,
      onskelage: e.target[1].value,
      atgarder: [
        {
          text: e.target[2].value,
          selected: true
        }
      ]
    }
    this.setState(state => {
      if(!state.data[state.currentSituation].customCards[0].changed) {
        state.data[state.currentSituation].selected++;
      }
      state.data[state.currentSituation].customCards[0] = card
      return state;
    })
    e.preventDefault();
  }

  toggleContainer(index) {
    this.setState({
      activeCards: {
        ...this.state.activeCards,
        [index]: !this.state.activeCards[index]
      }
    })
  }
  removeCustomCard(e, i) {
    this.setState(state => {
      state.data[state.currentSituation].customCards.splice(i, 1);
      return state;
    })
  }

  toggleMeasures(card, measure) {
    this.setState(state => {
      if (state.data[this.state.currentSituation].cards.cards[card].atgarder[measure].selected) {
        state.data[this.state.currentSituation].selected--;
      } else {
        state.data[this.state.currentSituation].selected++;
      }
      state.data[this.state.currentSituation].cards.cards[card].atgarder[measure].selected = !state.data[this.state.currentSituation].cards.cards[card].atgarder[measure].selected;
      return state
    })
  }

  render() {
    if(!this.props.location.state) {
      return <Redirect to={'/start'} />
    }

    const cards = this.state.data[this.state.currentSituation].cards.cards.map((card, index) => (
      <Card
        key={index}
        card={card}
        index={index}
        show={this.state.activeCards[index]}
        toggleContainer={this.toggleContainer.bind(this)}
        toggleMeasures={this.toggleMeasures.bind(this)}
      />
    ))

    const customCards = this.state.data[this.state.currentSituation].customCards.map((card, i) => (
      <CustomCard
        setSelected={this.setSelected.bind(this)}
        key={i}
        index={i}
        handleSubmit={this.handleSubmit.bind(this)}
        card={card}
        changeActive={this.changeActive.bind(this)}
      />
    ))

    return (
      <Outer>
        <Inner>
          <Sheet>
            <SituationHeader
              setHeaders={this.setHeaders.bind(this)}
              situation={this.state.data[this.state.currentSituation]}
            />
          {
            cards
          }
          <hr css={{width: '90%'}}/>
          <HeaderWrapper>
            <HeaderDescription>{this.state.data[this.state.currentSituation].cards.cards.length > 0 && "Om inga av påståendena ovan passar in kan du göra en egen beskrivning genom att fylla i de tre fälten nedan"}
            </HeaderDescription>
          </HeaderWrapper>
          {
            customCards
          }

          <Wrapper>
            <Button as={Link} to={{
                pathname: '/situations',
                state: {
                  ...this.props.location.state,
                  data: this.state.data
                }
              }}>
              ← Kartlägg fler aktiviteter
            </Button>
            <Button as={Link} to={{
                pathname: '/summary',
                state: {
                  ...this.props.location.state
                }
              }}>
            Skapa rapport →
            </Button>
          </Wrapper>
        </Sheet>

      </Inner>
      <Sticky id="sticky-div">
          <Explanation custom={this.props.location.state.data[this.props.location.state.currentSituation].cards.custom} setSelected={this.setSelected.bind(this)} selectedInfoCard={this.state.selectedInfoCard} fieldInformation={this.props.behaviorInfo}/>
      </Sticky>
    </Outer>
  )
  }
}

export default withRouter(Behavior)
