import React, { Component } from 'react'
import styled from 'styled-components/macro'


const Table = styled.table({
  width: '100%',
  border: '1px solid grey',
  margin: '20px 0',
  paddingTop: '10px'
})

const Th = styled.th({
  border: 'none',
})

export default class Checklist extends Component {

  render() {
    if(this.props.data.changed === false) {
      return null;
    }
    return (
        <Table>
          <thead>
            <tr>
              <Th css={{textAlign: 'start'}} colSpan={10}>
                <p css={{margin: '0  0 15px 5px', fontWeight: 700}}>{this.props.data.onskelage}</p>
              </Th>
            </tr>
          </thead>
          <tbody>
            <tr>
                <td css={{fontWeight: 600}}>Testat</td>
                <td css={{padding: '10px', fontWeight: 600}}>Anpassning att tillämpa</td>
            </tr>
            {
              this.props.data.atgarder.map((atgard, atgardIndex)=> {
                if(atgard.selected) {
                  return (
                    <tr key={atgardIndex}>
                      <td css={{width:'5%', textAlign: 'center', verticalAlign: 'middle'}}>
                        <input type="checkbox" disabled></input>
                      </td>
                      <td css={{padding: '10px 10px'}}>{atgard.text}</td>
                    </tr>
                  )
                }
                return null
              })
            }
            <tr>
              <td css={{textAlign: 'center', verticalAlign: 'middle'}}>
                <input type="checkbox" disabled></input>
              </td>
              <td css={{height:'50px', width: '100%', padding: '10px 10px'}}>Egen anpassning</td>
            </tr>
            <tr>
              <td colSpan={2} css={{height:'140px', textAlign: 'center', verticalAlign: 'top', fontWeight: 600}}>Utvärdering:</td>
            </tr>
          </tbody>
        </Table>
    )
  }
}
