import React from 'react'
import { Helmet } from 'react-helmet'
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'

import Login from './screens/Login'
import Start from './screens/Start'
import Situations from './screens/Situations'
import Behavior from './screens/Behavior'
import Summary from './screens/Summary'
import PersonalInfo from './screens/PersonalInfo'
import TopBar from './lib/TopBar'
import PageContainer from './lib/PageContainer'
import Steps from './lib/Steps'
import ContentfulProvider from './ContentfulProvider'

function Routing({ isAuthenticated }) {
  if (isAuthenticated && isAuthenticated.value) {
    if(window.Intercom) {
      window.Intercom('boot', {
       user_id: isAuthenticated.username,
       app_id: 're6bwqlr',
     })
    }
    return (
      <ContentfulProvider>
        {({introParagraphs, situations, situationsHeader, behaviorInfo}) => {
          return (
            <>
            <PageContainer>
              <Steps />
            </PageContainer>
              <Switch>
                <Route
                  path="/start"
                  render={() => (
                      <PageContainer>
                      <Helmet>
                        <title>Introduktion</title>
                      </Helmet>
                      <Start {...{ introParagraphs }} />
                      </PageContainer>
                  )}
                />
                <Route
                  path="/personalinfo"
                  render={() => {
                    let sits = situations.map(situation => {
                      return {...situation, customCards: [{changed: false, active: false, beteendehinder: undefined, onskelage: undefined, atgarder: []}], selected: 0, }
                    })
                    return (
                      <PageContainer>
                        <Helmet>
                          <title>Kartläggning</title>
                        </Helmet>
                        <PersonalInfo data={sits}/>
                      </PageContainer>
                    )
                  }}
                />
                <Route
                  path="/situations"
                  render={() => (
                    <PageContainer>
                      <Helmet>
                        <title>Situationer</title>
                      </Helmet>
                      <Situations {...{ situationsHeader }}/>
                    </PageContainer>
                  )}
                />
                <Route
                  path="/behavior"
                  render={() => (
                    <>
                      <Helmet>
                        <title>Hinder & Önskeläge</title>
                      </Helmet>
                      <Behavior behaviorInfo={behaviorInfo}/>
                    </>
                  )}
                />
                <Route
                  path="/summary"
                  render={() => (
                    <PageContainer>
                      <Helmet>
                        <title>Sammanfattning</title>
                      </Helmet>
                      <Summary/>
                    </PageContainer>
                  )}
                />
                <Redirect to={'/start'} />
              </Switch>
            </>
          )
        }}
      </ContentfulProvider>
    )
  } else {
    return (
      <Switch>
        <Route path="/login" component={Login} />
        <Redirect to="/login" />
      </Switch>
    )
  }
}

function App() {
  const isAuthenticated = JSON.parse(
    window.localStorage.getItem('isAuthenticated'),
  )
  return (
    <Router>
      <>
        <TopBar isAuthenticated={isAuthenticated} />
        <Routing isAuthenticated={isAuthenticated} />
      </>
    </Router>
  )
}

export default App
